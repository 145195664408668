.ql-mention-list-container {
	min-width: 270px;
	max-height: 220px;
	border: 1px solid #f0f0f0;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
	z-index: 9001;
	overflow: auto;
}

.ql-mention-loading {
	line-height: 44px;
	padding: 0 20px;
	vertical-align: middle;
	font-size: 16px;
}

.ql-mention-list {
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.ql-mention-list-item {
	cursor: pointer;
	line-height: 44px;
	font-size: 16px;
	padding: 0 20px;
	vertical-align: middle;
}

.ql-mention-list-item.disabled {
	cursor: auto;
}

.ql-mention-list-item.selected {
	background-color: #d3e1eb;
	text-decoration: none;
}

.mention {
	height: 24px;
	width: 65px;
	border-radius: 6px;
	background-color: #d3e1eb;
	padding: 3px 0;
	margin-right: 2px;
	user-select: all;
}

.mention > span {
	margin: 0 3px;
}
